import React, { PropsWithChildren } from "react"
import { Role } from "../../../../common/model/roles"
import { useUserSessionContext } from "../../context/UserSessionContext"

type AuthorizeProps = {
  roles: ReadonlyArray<Role>
}

export const Authorize: React.FC<PropsWithChildren<AuthorizeProps>> = ({
  roles,
  children,
}) => {
  const { userSession } = useUserSessionContext()

  if (!roles.includes(userSession.role)) {
    return <></>
  }

  return <>{children}</>
}
