import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { CognitoUser } from "amazon-cognito-identity-js"
import { App as AntApp } from "antd"
import { Auth } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import { Api, createApiUsing } from "./api"
import { ApiContext } from "./ApiContext"
import "./App.css"
import { AppContent } from "./AppContent"
import AppContextProvider from "./context/AppContext"
import {
  UserSession,
  useUserSessionContext,
} from "./context/UserSessionContext"
import { CertificatesView } from "./views/CertificatesView"
import { ClientsView } from "./views/ClientsView"
import { ContactsView } from "./views/ContactsView"
import { EmployeesView } from "./views/EmployeesView"
import { KeywordCategoriesView } from "./views/KeywordCategoriesView"
import { KeywordsView } from "./views/KeywordsView"
import { ProjectsView } from "./views/ProjectsView"
import { SearchView } from "./views/SearchView"
import { UsersView } from "./views/UsersView"
import { VendorsView } from "./views/VendorsView"
import { VerticalsView } from "./views/VerticalsView"

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppContent />,
    children: [
      {
        index: true,
        element: <Navigate to="/search" />,
      },
      {
        path: "search",
        element: <SearchView />,
      },
      {
        path: "projects",
        element: <ProjectsView />,
      },
      {
        path: "clients",
        element: <ClientsView />,
      },
      {
        path: "employees",
        element: <EmployeesView />,
      },
      {
        path: "keywords",
        element: <KeywordsView />,
      },
      {
        path: "keyword-categories",
        element: <KeywordCategoriesView />,
      },
      {
        path: "verticals",
        element: <VerticalsView />,
      },
      {
        path: "vendors",
        element: <VendorsView />,
      },
      {
        path: "contacts",
        element: <ContactsView />,
      },
      {
        path: "users",
        element: <UsersView />,
      },
      {
        path: "certificates",
        element: <CertificatesView />,
      },
    ],
  },
])

export const App: React.FC = () => {
  const { userSession, dispatchUserSession } = useUserSessionContext()
  const [api, setApi] = useState<Api>(
    createApiUsing(() => {
      throw new Error("API not initialized")
    }),
  )

  useEffect(() => {
    const refreshSessionIfNeeded = (user: CognitoUser) =>
      new Promise((resolve, reject) => {
        const session = user.getSignInUserSession()
        if (!session || !session.isValid()) {
          user.refreshSession(
            user.getSignInUserSession()!.getRefreshToken(),
            (err, result) => {
              if (err) {
                reject(err)
              } else {
                resolve(result)
              }
            },
          )
        } else {
          resolve(undefined)
        }
      })

    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        const idToken = user.getSignInUserSession()!.getIdToken()
        const accessToken = user.getSignInUserSession()!.getAccessToken()

        const idTokenProvider = async () => {
          await refreshSessionIfNeeded(user)
          return user.getSignInUserSession()!.getIdToken().getJwtToken()
        }

        const accessTokenProvider = async () => {
          await refreshSessionIfNeeded(user)
          return user.getSignInUserSession()!.getAccessToken().getJwtToken()
        }

        const roleProvider = async () => {
          await refreshSessionIfNeeded(user)
          return user.getSignInUserSession()!.getIdToken().payload.role
        }

        const session: UserSession = {
          authenticated: true,
          username: user.getUsername(),
          firstName: idToken.payload.given_name,
          lastName: idToken.payload.family_name,
          roleProvider,
          accessTokenProvider,
          idTokenProvider,
          role: idToken.payload.role,
        }

        setApi(createApiUsing(accessTokenProvider))

        dispatchUserSession({ action: "login", session })
      })
      .catch((e) => {
        dispatchUserSession({ action: "logout" })
        // How to handle the case where user is not found from DynamoDB
        if (String(e) === "The user is not authenticated") {
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Cognito,
          })
        }
      })
  }, [dispatchUserSession])

  if (!userSession.authenticated) {
    return (
      <AntApp>
        <div></div>
      </AntApp>
    )
  }

  return (
    <AntApp>
      <ApiContext.Provider value={api}>
        <AppContextProvider>
          <RouterProvider router={router} />
        </AppContextProvider>
      </ApiContext.Provider>
    </AntApp>
  )
}
