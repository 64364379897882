import _ from "lodash"
import { DataItem, Versioned } from "../../../common/model/common"
import {
  ServerError,
  TokenExpiredError,
  UnauthorizedError,
} from "../errors/api-errors"

const stringify = (obj: unknown): string =>
  JSON.stringify(obj, (key, val) => (val === null ? undefined : val))

const TOKEN_EXPIRATION_MESSAGE = "The incoming token has expired"

type ResponseHandler = (response: Response) => Promise<any>

const defaultResponseHandler: ResponseHandler = async (response) => {
  if (response.ok) {
    const payload = await response.json()
    return payload.data
  }

  const responseData = await response.json()
  const errorData = {
    statusCode: response.status,
    statusText: response.statusText,
    response: responseData,
  }
  if (
    response.status === 401 &&
    responseData.message === TOKEN_EXPIRATION_MESSAGE
  ) {
    throw new TokenExpiredError(errorData)
  }
  if (response.status === 403) {
    throw new UnauthorizedError(errorData)
  }

  throw new ServerError(errorData)
}

const blobResponseHandler: ResponseHandler = async (response) => {
  if (response.ok) {
    return await response.blob()
  }

  const responseData = await response.json()
  const errorData = {
    statusCode: response.status,
    statusText: response.statusText,
    response: responseData,
  }
  if (
    response.status === 401 &&
    responseData.message === TOKEN_EXPIRATION_MESSAGE
  ) {
    throw new TokenExpiredError(errorData)
  }
  if (response.status === 403) {
    throw new UnauthorizedError(errorData)
  }

  throw new ServerError(errorData)
}

const serializeQueryString = (
  queryStringParams?: Record<string, string | number | undefined>,
): string => {
  if (_.isEmpty(queryStringParams)) {
    return ""
  }

  return (
    "?" +
    Object.entries(queryStringParams)
      .filter(([key, value]) => value !== undefined)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`,
      )
      .join("&")
  )
}

type GetProps = {
  queryStringParams?: Record<string, string | number | undefined>
  headers?: Record<string, string>
}

const getWithTokenProvider =
  (
    authTokenProvider: () => Promise<String>,
    responseHandler: ResponseHandler = defaultResponseHandler,
  ) =>
  <T>(url: string, props?: GetProps): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(`${url}${serializeQueryString(props?.queryStringParams)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            ...props?.headers,
          },
        }),
      )
      .then(responseHandler)

const putWithTokenProvider =
  (
    authTokenProvider: () => Promise<String>,
    responseHandler: ResponseHandler = defaultResponseHandler,
  ) =>
  <T>(url: string, payload?: unknown): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(url, {
          method: "PUT",
          body: payload ? stringify(payload) : undefined,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
      )
      .then(responseHandler)

const delWithTokenProvider =
  (
    authTokenProvider: () => Promise<String>,
    responseHandler: ResponseHandler = defaultResponseHandler,
  ) =>
  <T>(url: string, props?: GetProps): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(`${url}${serializeQueryString(props?.queryStringParams)}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            ...props?.headers,
          },
        }),
      )
      .then(responseHandler)

const postWithTokenProvider =
  (
    authTokenProvider: () => Promise<String>,
    responseHandler: ResponseHandler = defaultResponseHandler,
  ) =>
  <T>(url: string, payload?: unknown): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(url, {
          method: "POST",
          body: payload ? stringify(payload) : undefined,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
      )
      .then(responseHandler)

export interface HttpClient {
  get: <T>(url: string, props?: GetProps) => Promise<T>
  download: <T>(url: string, props?: GetProps) => Promise<T>
  post: <T>(url: string, payload?: unknown) => Promise<T>
  put: <T>(url: string, payload?: unknown) => Promise<T>
  del: <T>(url: string, props?: GetProps) => Promise<T>
}

export const httpClientUsing = (
  authTokenProvider: () => Promise<string>,
): HttpClient => ({
  get: getWithTokenProvider(authTokenProvider),
  download: getWithTokenProvider(authTokenProvider, blobResponseHandler),
  put: putWithTokenProvider(authTokenProvider),
  post: postWithTokenProvider(authTokenProvider),
  del: delWithTokenProvider(authTokenProvider),
})

export type HttpClientV2<
  DATA_ITEM extends DataItem,
  CREATE_REQUEST extends object,
  UPDATE_REQUEST extends Versioned,
> = {
  getById: (id: string) => Promise<DATA_ITEM>
  create: (request: CREATE_REQUEST) => Promise<DATA_ITEM>
  update: (id: string, request: UPDATE_REQUEST) => Promise<DATA_ITEM>
  deleteById: (id: string, newId?: string) => Promise<string>
  list: () => Promise<ReadonlyArray<DATA_ITEM>>
  export: () => Promise<unknown>
}

export type HttpClientV2Props<T extends DataItem> = {
  basePath: string
  authTokenProvider: () => Promise<string>
  convert?: (data: T) => T
}

export const httpClientV2Using = <
  DATA_ITEM extends DataItem,
  CREATE_REQUEST extends object,
  UPDATE_REQUEST extends Versioned,
>({
  basePath,
  authTokenProvider,
  convert = (data) => data,
}: HttpClientV2Props<DATA_ITEM>): HttpClientV2<
  DATA_ITEM,
  CREATE_REQUEST,
  UPDATE_REQUEST
> => {
  const client = httpClientUsing(authTokenProvider)

  return {
    getById: (id: string) =>
      client
        .get(`${basePath}/${id}`)
        .then((data) => convert(data as DATA_ITEM)),
    create: (request: CREATE_REQUEST) =>
      client.post(basePath, request).then((data) => convert(data as DATA_ITEM)),
    update: (id: string, request: UPDATE_REQUEST) =>
      client
        .put(`${basePath}/${id}`, request)
        .then((data) => convert(data as DATA_ITEM)),
    deleteById: (id: string, newId?: string) =>
      client
        .del(`${basePath}/${id}`, { queryStringParams: { newId } })
        .then(() => id),
    list: () =>
      client.get(basePath).then((data) => (data as DATA_ITEM[]).map(convert)),
    export: () =>
      client.download(`${basePath}/export`, {
        headers: { "Content-type": "application/csv; charset=UTF-8" },
      }),
  }
}
