import { Typography } from "antd"
import { Keyword } from "../../../common/model/keywords"
import { KeywordForm, MergeKeywordsForm } from "../components/form/KeywordForm"
import { UploadForm } from "../components/form/UploadForm"
import { resolveKeywordCategoryName } from "../components/render/KeywordCategoryName"
import { KeywordListItem } from "../model/keywords"
import { KeywordDetailsView } from "./KeywordDetailsView"
import { listingView } from "./ListingView"

export const KeywordsView = listingView<Keyword, KeywordListItem>({
  itemsKey: "keywords",
  addItemTitle: "New Keyword",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected keywords?",
  addItemForm: <KeywordForm />,
  editItemForm: (item) => <KeywordForm itemId={item.id} />,
  detailsView: (item) => <KeywordDetailsView itemId={item.id} />,
  mergeItemsForm: (items) => (
    <MergeKeywordsForm itemIds={items.map((i) => i.id)} />
  ),
  uploadItemsForm: (
    <UploadForm
      itemsKey="keywords"
      dependencies={["keyword-categories"]}
      examples={[
        {
          description: (
            <Typography.Text>
              Import keywords without the <code>id</code> column. Should be used
              when creating new keywords.
            </Typography.Text>
          ),
          rows: [
            "name, category",
            "Java, Programming Languages",
            "AWS, Cloud Services",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import keywords with the <code>id</code> column. Should be used
              when modifying existing keywords. Note that the <code>id</code>{" "}
              column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, category, id",
            "Java, Programming Languages, 14a3e998-4ba5-4de4-94ad-ee5f8348f199",
            "AWS, Cloud Services, 94eff251-de39-4bad-8f2c-05a5a189a988",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "name",
              required: true,
              description: (
                <Typography.Text>
                  Keyword name. Used to find an existing keyword to update if
                  the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "category",
              description:
                "Name of the keyword category where the keyword belongs to. " +
                "If no keyword category exists with the given name, a new keyword category will be created.",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Keyword id. Used to find an existing keyword to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update name of an
                      existing keyword.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  actions: ["remove", "merge"],
  columns: [
    {
      property: "name",
      title: "Name",
      width: "400px",
    },
    {
      property: "categoryName",
      title: "Keyword Category",
    },
    {
      property: "projectCount",
      title: "Projects",
    },
  ],
  toListItem: (item, appState) => {
    const categoryName = resolveKeywordCategoryName(appState, item.categoryId)
    const projectCount = (appState.projects.data ?? []).filter(
      (p) =>
        p.keywordIds?.includes(item.id) ||
        p.roles?.some((r) => r.keywordIds?.includes(item.id)),
    ).length

    return {
      ...item,
      text: [item.name, categoryName].join(" ").toLowerCase(),
      categoryName,
      projectCount,
    }
  },
})
