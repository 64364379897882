import { Space, Typography } from "antd"
import { KeywordCategory } from "../../../common/model/keyword-categories"
import { KeywordCategoryForm } from "../components/form/KeywordCategoryForm"
import { detailsView } from "./DetailsView"

export const KeywordCategoryDetailsView = detailsView<KeywordCategory>({
  itemsKey: "keyword-categories",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <KeywordCategoryForm
      itemId={item.id}
      onCancel={onCancel}
      onComplete={onComplete}
    />
  ),
  view: (item, appState) => {
    const keywords = (appState.keywords.data ?? []).filter(
      (k) => k.categoryId === item.id,
    )

    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Description</Typography.Text>
        <Typography.Text>{item.description ?? "-"}</Typography.Text>
        <Typography.Text strong>Keywords</Typography.Text>
        <Typography.Text>
          <ul>
            {keywords?.map((keyword) => (
              <li>{keyword.name}</li>
            ))}
          </ul>
        </Typography.Text>
      </Space>
    )
  },
})
