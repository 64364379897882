import {
  Client,
  ClientBase,
  CreateClientRequest,
  UpdateClientRequest,
} from "../../../../common/model/clients"
import { dataItemForm } from "./DataItemForm"

export const ClientForm = dataItemForm<
  Client,
  ClientBase,
  CreateClientRequest,
  UpdateClientRequest
>({
  itemsKey: "clients",
  fields: [
    {
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
    },
    {
      type: "select-one",
      name: "verticalId",
      label: "Vertical",
      placeholder: "Vertical",
      required: true,
      wide: true,
      options: (appState) =>
        (appState.verticals.data ?? []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
