import {
  App,
  Button,
  Col,
  Form,
  FormInstance,
  FormProps,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd"
import _ from "lodash"
import type { NamePath } from "rc-field-form/lib/interface"
import React, { useContext, useEffect, useState } from "react"
import { DataItem, Versioned } from "../../../../common/model/common"
import { Api } from "../../api"
import { HttpClientV2 } from "../../api/http-client"
import { ApiContext } from "../../ApiContext"
import { AppState, useAppContext } from "../../context/AppContext"
import { AppDrawerContext } from "../AppDrawer"

interface SubmitButtonProps {
  form: FormInstance
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  form,
  children,
}) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false)

  // Watch all values
  const values = Form.useWatch([], form)

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false))
  }, [form, values])

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  )
}

// TODO: proper typing for different fields
type Field<T> = {
  name: NamePath<T>
  label: string
  placeholder: string
  extra?: string
  type?: "text" | "long-text" | "select-one" | "select-multiple"
  required?: boolean
  wide?: boolean
  focus?: boolean
  options?: (
    appState: AppState,
  ) => Array<{ value: string | boolean | number; label: string }>
}

type CreateMergeDataItemsFormProps<
  DATA_ITEM extends DataItem,
  FORM_ITEM extends object,
  UPDATE_REQUEST extends Versioned,
> = {
  fields: ReadonlyArray<Field<FORM_ITEM>>
  itemsKey: keyof AppState
  toForm: (item: DATA_ITEM) => FORM_ITEM
  toUpdateRequest: (item: FORM_ITEM, version: number) => UPDATE_REQUEST
  dependents?: ReadonlyArray<keyof AppState>
}

type MergeDataItemsFormProps<DATA_ITEM extends DataItem> = {
  itemIds: ReadonlyArray<string>
  onCancel?: () => void
  onComplete?: (item: DATA_ITEM) => void
}

export const mergeDataItemsForm = <
  DATA_ITEM extends DataItem,
  FORM_ITEM extends object,
  UPDATE_REQUEST extends Versioned,
>(
  props: CreateMergeDataItemsFormProps<DATA_ITEM, FORM_ITEM, UPDATE_REQUEST>,
): React.FC<MergeDataItemsFormProps<DATA_ITEM>> => {
  let currentRow = new Array<Field<FORM_ITEM>>()
  const fieldRows = new Array(currentRow)

  props.fields.forEach((field) => {
    if (field.wide) {
      currentRow = [field]
      fieldRows.push(currentRow)
    } else {
      currentRow.push(field)
    }
  })

  const MergeDataItemsForm: React.FC<MergeDataItemsFormProps<DATA_ITEM>> = (
    formProps: MergeDataItemsFormProps<DATA_ITEM>,
  ) => {
    const drawerState = useContext(AppDrawerContext)
    const { appState, dispatchAppState } = useAppContext()
    const { notification } = App.useApp()
    const api = useContext(ApiContext)
    const [loading, setLoading] = useState(false)
    const [initialValue, setInitialValue] = useState<FORM_ITEM | undefined>()
    const [existingItems, setExistingItems] = useState<
      ReadonlyArray<DATA_ITEM>
    >([])
    const [initialLoading, setInitialLoading] = useState(true)
    const [form] = Form.useForm()

    // TODO: Remove casts
    const crud = api[props.itemsKey as keyof Api] as unknown as HttpClientV2<
      DATA_ITEM,
      any,
      UPDATE_REQUEST
    >

    const fetchData = () => {
      Promise.all(formProps.itemIds.map((itemId) => crud.getById(itemId))).then(
        (items) => {
          setExistingItems(items)
          setInitialLoading(false)
        },
      )
    }

    useEffect(() => {
      fetchData()
    }, [])

    useEffect(() => {
      const handleKeyPress = (event: any) => {
        if (event.metaKey === true) {
          if (event.key === "Enter") {
            form.submit()
          }
        }
      }

      // attach the event listener
      document.addEventListener("keydown", handleKeyPress)

      // remove the event listener
      return () => {
        document.removeEventListener("keydown", handleKeyPress)
      }
    })

    const onFinish: FormProps<FORM_ITEM>["onFinish"] = (values: FORM_ITEM) => {
      setLoading(true)

      const [firstItem, ...restItems] = existingItems
      if (!firstItem) {
        throw new Error("This should not happen")
      }

      if (restItems.length === 0) {
        throw new Error("This should not happen")
      }

      const idsToDelete = restItems.map((itemToDelete) => itemToDelete.id)

      const operation = () => {
        const request = props.toUpdateRequest(values, firstItem.version)
        console.log("Update request")
        console.log(JSON.stringify(request, null, 2))

        return crud.update(firstItem.id, request)
      }
      operation()
        .then(async (data) => {
          await Promise.all(
            idsToDelete.map((idToDelete) =>
              crud.deleteById(idToDelete, firstItem.id),
            ),
          )

          dispatchAppState({
            data: [data],
            action: "update-data-items",
            key: props.itemsKey,
          })

          dispatchAppState({
            ids: idsToDelete,
            action: "remove-data-items",
            key: props.itemsKey,
          })

          const keysToRefresh = _.uniq(props.dependents ?? [])

          const refreshedDependents = await Promise.all(
            keysToRefresh.map(async (key) => {
              dispatchAppState({
                key,
                action: "update-data",
                stage: "start",
              })

              // TODO: Remove casts
              const crud = api[key as keyof Api] as unknown as HttpClientV2<
                any,
                any,
                any
              >

              return { key, data: await crud.list() }
            }),
          )

          refreshedDependents.forEach(({ key, data }) => {
            dispatchAppState({
              action: "update-data",
              stage: "complete",
              key,
              data,
            })
          })

          notification.success({
            message: "Merge succeeded!",
          })

          const onComplete =
            formProps.onComplete ?? ((d: DATA_ITEM) => drawerState.close())

          onComplete(data)

          setLoading(false)
        })
        .catch((err) => {
          notification.error({
            message: "Merge failed!",
          })
          setLoading(false)
        })
    }

    const onFinishFailed: FormProps<FORM_ITEM>["onFinishFailed"] = (
      errorInfo,
    ) => {
      // console.log("Failed:", errorInfo)
    }

    const rows = fieldRows.map((fields, rowIndex) => {
      const cols = fields.map((field, fieldIndex) => {
        const span = field.wide ? 24 : 12

        let input = <Input placeholder={field.placeholder} />

        switch (field.type ?? "text") {
          case "text":
            input = (
              <Radio.Group>
                <Space direction="vertical">
                  {existingItems.map((item: any) => (
                    <Radio key={item.id} value={item[field.name]}>
                      {item[field.name]}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )

            break
          case "long-text":
            input = <Input.TextArea rows={10} placeholder={field.placeholder} />
            break
          case "select-one":
            const currentOptions = existingItems.map(
              (item: any) => item[field.name],
            )

            const availableOptions = field.options!(appState).filter((o) =>
              currentOptions.includes(o.value),
            )

            input = (
              <Radio.Group>
                <Space direction="vertical">
                  {availableOptions.map((option) => (
                    <Radio key={option.label} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )

            break
          case "select-multiple":
            input = (
              <Select
                options={field.options ? field.options(appState) : []}
                placeholder={field.placeholder}
                optionFilterProp="label"
                mode="multiple"
                allowClear
                showSearch
              />
            )
            break
          default:
            throw new Error(`Unknown field type: ${field.type}`)
        }

        return (
          <Col span={span} key={fieldIndex}>
            <Form.Item<FORM_ITEM>
              name={field.name}
              label={field.label}
              extra={field.extra}
              rules={[{ required: field.required }]}
            >
              {input}
            </Form.Item>
          </Col>
        )
      })

      return (
        <Row gutter={16} key={rowIndex}>
          {cols}
        </Row>
      )
    })

    if (initialLoading) {
      return <Skeleton active />
    }

    const onCancel =
      formProps.onCancel ??
      (() => {
        drawerState.close()
      })

    return (
      <Form
        form={form}
        clearOnDestroy
        disabled={loading || initialLoading}
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        name="validateOnly"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValue}
      >
        {rows}
        <Row gutter={16}>
          <Col span={25}>
            <Form.Item>
              <Space>
                <SubmitButton form={form}>Save (cmd + Enter)</SubmitButton>
                <Button onClick={onCancel}>Cancel (Esc)</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  return MergeDataItemsForm
}
