import { Space, Typography } from "antd"
import { Client } from "../../../common/model/clients"
import { ClientForm } from "../components/form/ClientForm"
import { resolveVerticalName } from "../components/render/VerticalName"
import { detailsView } from "./DetailsView"

export const ClientDetailsView = detailsView<Client>({
  itemsKey: "clients",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <ClientForm itemId={item.id} onCancel={onCancel} onComplete={onComplete} />
  ),
  view: (item, appState) => {
    const projects = (appState.projects.data ?? [])
      .filter((p) => p.clientId === item.id)
      .sort((a, b) => a.name.localeCompare(b.name))

    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Vertical</Typography.Text>
        <Typography.Text>
          {resolveVerticalName(appState, item.verticalId)}
        </Typography.Text>
        <Typography.Text strong>Projects</Typography.Text>
        <Typography.Text>
          <ul>
            {projects.map((project) => (
              <li>{project.name}</li>
            ))}
          </ul>
        </Typography.Text>
      </Space>
    )
  },
})
