import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import {
  CreateProjectRequest,
  Project,
  UpdateProjectRequest,
} from "../../../../common/model/projects"
import { ProjectFormData } from "../../model/projects"
import { projectFormDetails } from "./ProjectFormDetails"

const getStartDate = (date?: Dayjs): number | undefined => date?.valueOf()

const getEndDate = (date?: Dayjs): number | undefined =>
  date?.endOf("month").valueOf()

const toDate = (timestamp?: number): Dayjs | undefined =>
  timestamp ? dayjs(timestamp) : undefined

export const ProjectForm = projectFormDetails<
  Project,
  ProjectFormData,
  CreateProjectRequest,
  UpdateProjectRequest
>({
  itemsKey: "projects",
  toForm: (project, appState) => {
    return {
      ..._.omit(project, ["keywordIds", "startDate", "endDate"]),
      startDate: toDate(project.startDate),
      endDate: toDate(project.endDate),
      keywords: (project.keywordIds ?? []).reduce(
        (collected, keywordId) => ({ ...collected, [keywordId]: true }),
        {},
      ),
      assignments: project.assignments?.map((assignment) => ({
        ...assignment,
        startDate: toDate(assignment.startDate),
        endDate: toDate(assignment.endDate),
      })),
    }
  },
  toCreateRequest: (form, appState) => {
    return {
      ..._.omit(form, ["startDate", "endDate", "keywords", "assignments"]),
      startDate: getStartDate(form.startDate),
      endDate: getEndDate(form.endDate),
      keywordIds: Object.entries(form.keywords ?? {})
        .filter(([keywordId, value]) => value)
        .map(([keywordId, value]) => keywordId),
      roles: form.roles?.map((role) => ({
        ..._.omit(role, ["keywords"]),
        keywordIds: Object.entries(role.keywords ?? {})
          .filter(([keywordId, value]) => value)
          .map(([keywordId, value]) => keywordId),
      })),
      assignments: form.assignments?.map((assignment) => ({
        ...assignment,
        startDate: getStartDate(assignment.startDate),
        endDate: getEndDate(assignment.endDate),
      })),
    }
  },
  toUpdateRequest: (form, version, appState) => {
    return {
      ..._.omit(form, ["startDate", "endDate", "keywords", "assignments"]),
      code: form.code!,
      name: form.name!,
      clientId: form.clientId,
      vendorId: form.vendorId,
      startDate: getStartDate(form.startDate),
      endDate: getEndDate(form.endDate),
      keywordIds: Object.entries(form.keywords ?? {})
        .filter(([keywordId, value]) => value)
        .map(([keywordId, value]) => keywordId),
      roles: form.roles?.map((role) => ({
        ..._.omit(role, ["keywords"]),
        keywordIds: Object.entries(role.keywords ?? {})
          .filter(([keywordId, value]) => value)
          .map(([keywordId, value]) => keywordId),
      })),
      assignments: form.assignments?.map((assignment) => ({
        ...assignment,
        startDate: getStartDate(assignment.startDate),
        endDate: getEndDate(assignment.endDate),
      })),
      version,
    }
  },
  fields: [
    {
      name: "vendorId",
      label: "Vendor",
      placeholder: "Vendor",
      required: true,
      wide: true,
      type: "select-one",
      options: (appState) =>
        (appState.vendors.data ?? []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
    {
      name: "clientId",
      label: "Client",
      placeholder: "Client",
      required: true,
      wide: true,
      type: "select-one",
      options: (appState) =>
        (appState.clients.data ?? []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
    {
      name: "clientContactId",
      label: "Client Contact",
      placeholder: "Client Contact",
      required: false,
      wide: true,
      type: "select-one",
      options: (appState) =>
        (appState.contacts.data ?? []).map(({ id, firstName, lastName }) => ({
          value: id,
          label: `${lastName} ${firstName}`,
        })),
    },
    {
      name: "name",
      label: "Name",
      placeholder: "Project Name",
      required: true,
      wide: true,
    },
    {
      name: "code",
      label: "Project Code",
      placeholder: "Unique code that is used to identify the project",
      required: true,
      wide: true,
    },
    {
      name: "startDate",
      label: "Project Start Month (inclusive)",
      placeholder: "Project Start",
      type: "month",
      required: false,
    },
    {
      name: "endDate",
      label: "Project End Month (inclusive)",
      placeholder: "Project End",
      type: "month",
      required: false,
    },
    {
      name: "description",
      type: "long-text",
      label: "Description",
      placeholder: "Description",
      required: false,
      wide: true,
    },
  ],
})
