import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type CertificateNameProps = {
  id: string
  includeOrganization?: boolean
  defaultValue?: string | false
}

export const resolveCertificateName = (
  appState: AppState,
  certificateId?: string,
  includeOrganization?: boolean,
  defaultValue: string | false = "-",
): string => {
  if (!certificateId) {
    return "-"
  }

  const certificate = appState.certificates.data?.find(
    (v) => v.id === certificateId,
  )

  if (certificate) {
    return includeOrganization
      ? certificate.organization + " / " + certificate.name
      : certificate.name
  }

  if (defaultValue === false) {
    return certificateId
  }

  return defaultValue
}

export const CertificateName: React.FC<CertificateNameProps> = ({
  id,
  includeOrganization,
  defaultValue,
}: CertificateNameProps) => {
  const { appState } = useAppContext()
  return (
    <>
      {resolveCertificateName(appState, id, includeOrganization, defaultValue)}
    </>
  )
}
