import {
  Certificate,
  CertificateBase,
  CreateCertificateRequest,
  UpdateCertificateRequest,
} from "../../../../common/model/certificate"
import { dataItemForm } from "./DataItemForm"
import { mergeDataItemsForm } from "./MergeDataItemsForm"

export const CertificateForm = dataItemForm<
  Certificate,
  CertificateBase,
  CreateCertificateRequest,
  UpdateCertificateRequest
>({
  itemsKey: "certificates",
  fields: [
    {
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
    },
    {
      type: "text",
      name: "organization",
      label: "Issuing Organization",
      placeholder: "Issuing Organization",
      required: true,
      wide: true,
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})

export const MergeCertificatesForm = mergeDataItemsForm<
  Certificate,
  CertificateBase,
  UpdateCertificateRequest
>({
  itemsKey: "certificates",
  dependents: [],
  fields: [
    {
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
    },
    {
      name: "organization",
      label: "Issuing Organization",
      placeholder: "Issuing Organization",
      required: true,
      wide: true,
    },
  ],
  toForm: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
