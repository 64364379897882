import {
  CreateKeywordCategoryRequest,
  KeywordCategory,
  KeywordCategoryBase,
  UpdateKeywordCategoryRequest,
} from "../../../../common/model/keyword-categories"
import { dataItemForm } from "./DataItemForm"

export const KeywordCategoryForm = dataItemForm<
  KeywordCategory,
  KeywordCategoryBase,
  CreateKeywordCategoryRequest,
  UpdateKeywordCategoryRequest
>({
  itemsKey: "keyword-categories",
  fields: [
    {
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
    },
    {
      type: "long-text",
      name: "description",
      label: "Description",
      placeholder: "Description",
      required: true,
      wide: true,
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
