import {
  CreateUserRequest,
  UpdateUserRequest,
  User,
  UserBase,
} from "../../../../common/model/users"
import { dataItemForm } from "./DataItemForm"
export const UserForm = dataItemForm<
  User,
  UserBase,
  CreateUserRequest,
  UpdateUserRequest
>({
  itemsKey: "users",
  fields: [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      required: true,
    },
    {
      type: "text",
      name: "email",
      label: "Email",
      placeholder: "Email",
      required: true,
    },
    {
      type: "select-one",
      name: "role",
      label: "Role",
      placeholder: "Role",
      required: true,
      options: () => [
        { label: "Employee", value: "employee" },
        { label: "Sales", value: "sales" },
        { label: "Admin", value: "admin" },
      ],
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
