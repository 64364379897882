import dayjs, { Dayjs } from "dayjs"
import {
  CreateEmployeeRequest,
  Employee,
  UpdateEmployeeRequest,
} from "../../../../common/model/employees"
import { EmployeeFormData } from "../../model/employees"
import { dataItemForm } from "./DataItemForm"

const toTimestamp = (date?: Dayjs): number | undefined => date?.valueOf()

const toDate = (timestamp?: number): Dayjs | undefined =>
  timestamp ? dayjs(timestamp) : undefined

export const EmployeeForm = dataItemForm<
  Employee,
  EmployeeFormData,
  CreateEmployeeRequest,
  UpdateEmployeeRequest
>({
  itemsKey: "employees",
  fields: [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      required: true,
    },
    {
      type: "text",
      name: "email",
      label: "Email",
      placeholder: "Email",
      required: true,
    },
    {
      type: "select-one",
      name: "current",
      label: "Current",
      placeholder: "Current",
      required: true,
      options: () => [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    {
      type: "select-one",
      name: "vendorId",
      label: "Vendor",
      placeholder: "Vendor",
      options: (appState) =>
        (appState.vendors.data ?? []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
    {
      type: "nested-list",
      name: "certifications",
      label: "Certifications",
      description: "Employee's certifications",
      addButtonText: "Add Certification",
      fields: [
        {
          type: "select-one",
          name: "certificateId",
          placeholder: "Certificate",
          label: "Certificate",
          required: true,
          options: (appState) =>
            (appState.certificates.data ?? []).map(
              ({ id, name, organization }) => ({
                value: id,
                label: organization + " / " + name,
              }),
            ),
          wide: true,
        },
        {
          type: "text",
          name: "credentialUrl",
          label: "Credential Url",
          placeholder: "Credential Url",
          required: false,
          wide: true,
        },
        {
          type: "text",
          name: "credentialId",
          label: "Credential Id",
          placeholder: "Credential Id",
          required: false,
          wide: true,
        },
        {
          type: "date",
          name: "issueDate",
          label: "Issue Date",
          placeholder: "Issue Date",
          required: false,
          wide: true,
        },
        {
          type: "date",
          name: "expirationDate",
          label: "Expiration Date",
          placeholder: "Expiration Date",
          required: false,
          wide: true,
        },
      ],
    },
  ],
  toForm: (a) => {
    const certifications = a.certifications?.map((c) => ({
      ...c,
      issueDate: toDate(c.issueDate),
      expirationDate: toDate(c.expirationDate),
    }))

    return { ...a, certifications }
  },
  toCreateRequest: (a) => {
    const certifications = a.certifications?.map((c) => ({
      ...c,
      issueDate: toTimestamp(c.issueDate),
      expirationDate: toTimestamp(c.expirationDate),
    }))

    return {
      ...a,
      certifications,
    }
  },
  toUpdateRequest: (a, version) => {
    const certifications = a.certifications?.map((c) => ({
      ...c,
      issueDate: toTimestamp(c.issueDate),
      expirationDate: toTimestamp(c.expirationDate),
    }))

    return { ...a, certifications, version }
  },
})
