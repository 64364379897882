import { Space, Typography } from "antd"
import { Certificate } from "../../../common/model/certificate"
import { CertificateForm } from "../components/form/CertificateForm"
import { detailsView } from "./DetailsView"

export const CertificateDetailsView = detailsView<Certificate>({
  itemsKey: "certificates",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <CertificateForm
      itemId={item.id}
      onComplete={onComplete}
      onCancel={onCancel}
    />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
      </Space>
    )
  },
})
