import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from "react"
import { Role } from "../../../common/model/roles"

//
// User session state
//

export type UserSession = {
  authenticated: boolean
  username: string
  roleProvider: () => Promise<string>
  firstName: string
  lastName: string
  accessTokenProvider: () => Promise<string>
  idTokenProvider: () => Promise<string>
  role: Role
}

const initialUserSession = (): UserSession => ({
  authenticated: false,
  username: "",
  roleProvider: () => Promise.reject("Role not initialised"),
  firstName: "",
  lastName: "",
  accessTokenProvider: () => Promise.reject("Access token not initialised"),
  idTokenProvider: () => Promise.reject("Access token not initialised"),
  role: "none",
})

//
// Reducer to modify the application state
//
type UserSessionAction =
  | {
      action: "login"
      session: UserSession
    }
  | { action: "logout" }

export const userSessionReducer = (
  state: UserSession,
  action: UserSessionAction,
): UserSession => {
  if (action.action === "login") {
    return action.session
  } else if (action.action === "logout") {
    return initialUserSession()
  } else {
    throw new Error(`Not implemented action ${action}`)
  }
}

// Define the type for your context data
type UserSessionContextType = {
  userSession: UserSession
  dispatchUserSession: Dispatch<UserSessionAction>
}

// Create the context with an initial value of null
export const UserSessionContext = createContext<
  UserSessionContextType | undefined
>(undefined)

// Define the props type for the context provider component
type ContextProviderProps = {
  children: ReactNode
}

// Define the provider component
const UserSessionContextProvider = ({ children }: ContextProviderProps) => {
  const [userSession, dispatchUserSession] = useReducer(
    userSessionReducer,
    initialUserSession(),
  )

  return (
    <UserSessionContext.Provider value={{ userSession, dispatchUserSession }}>
      {children}
    </UserSessionContext.Provider>
  )
}

//
// Hooks
//

// Hook to use the context
export const useUserSessionContext = (): UserSessionContextType => {
  const context = useContext(UserSessionContext)

  if (!context) {
    throw new Error(
      "The User Session Context must be used within an UserSessionContextProvider",
    )
  }

  return context
}

export default UserSessionContextProvider
