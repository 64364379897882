import { Role } from "../../common/model/roles"

export type ResourceName =
  | "clients"
  | "keywords"
  | "keyword-categories"
  | "employees"
  | "projects"
  | "contacts"
  | "verticals"
  | "vendors"
  | "users"
  | "certificates"

export const RESOURCE_NAMES: ReadonlyArray<ResourceName> = [
  "clients",
  "keywords",
  "keyword-categories",
  "employees",
  "projects",
  "contacts",
  "verticals",
  "vendors",
  "users",
  "certificates",
] as const

type ResourceOperation =
  | "list"
  | "remove"
  | "update"
  | "import"
  | "export"
  | "create"

type ResourcePermissions = Record<
  ResourceName,
  Record<ResourceOperation, ReadonlyArray<Role>>
>

export const RESOURCE_PERMISSIONS: ResourcePermissions = {
  clients: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  keywords: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  "keyword-categories": {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  employees: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  projects: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  contacts: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  verticals: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  vendors: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  certificates: {
    list: ["admin", "sales", "employee"],
    remove: ["admin", "sales"],
    update: ["admin", "sales"],
    create: ["admin", "sales"],
    export: ["admin", "sales"],
    import: ["admin"],
  },
  users: {
    list: ["admin"],
    remove: ["admin"],
    update: ["admin"],
    create: ["admin"],
    export: ["admin"],
    import: ["admin"],
  },
} as const
